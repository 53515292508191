<template>
  <div class="cpt-mount cpt-MMC_Gimbal_L50">
    <div class="bd">
      <div class="form-wrap mount-form">
        <div class="form-item">
          <div class="label-box">常规模式</div>
          <div class="input-box">
            <el-switch
              @change="handle_bright"
              v-model="bright_status"
              size="mini"
            ></el-switch>
          </div>
        </div>
        <div v-if="!bright_status" class="form-item">
          <div class="label-box">闪光模式</div>
          <div class="input-box">
            <el-switch
              @change="handle_flicker"
              v-model="flicker_status"
              size="mini"
            ></el-switch>
          </div>
        </div>
        <div v-else class="form-item">
          <div class="label-box">极&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;亮</div>
          <div class="input-box">
            <el-checkbox
              @change="handle_very_bright"
              v-model="very_bright_status"
              size="mini"
            ></el-checkbox>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">回中开关</div>
          <div class="input-box">
            <el-switch
              @change="handle_middle"
              v-model="middle_status"
              size="mini"
            ></el-switch>
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="title-box">
          <div class="title">俯仰操作</div>
        </div>
        <div class="rocker-box">
          <div class="rocker" ref="rocker"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nipplejs from "nipplejs";
import { MMC_Gimbal_L50 } from "../utils";
export default {
  props: {
    whachChange:{
      typeof:Number,
      default:0
    }
  },
  data() {
    return {
      bright_status: false,
      flicker_status: false,
      very_bright_status: false,
      middle_status: false,
      joystick:null
    };
  },
  methods: {
    handle_bright(value) {
      let buffer = MMC_Gimbal_L50.constant_bright_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_flicker(value) {
      let buffer = MMC_Gimbal_L50.flicker_ctrl(!value);
      this.commit_directive(buffer);
    },
    handle_very_bright(value) {
      let buffer = MMC_Gimbal_L50.very_bright_ctrl(!value);
      this.commit_directive(buffer);
    },
    handle_middle(value) {
      let buffer = MMC_Gimbal_L50.middle_ctrl(!value);
      this.commit_directive(buffer);
    },
    commit_directive(buffer) {
      // console.log(buffer);
      this.$emit("directive", buffer);
    },

    init_rocker() {
      this.joystick = nipplejs.create({
        zone: this.$refs["rocker"],
        mode: "static",
        position: {
          left: "50%",
          top: "50%",
        },
        color: "#9bacbe",
      });

      this.joystick
        .on("start", this.start_timer)
        .on("end", this.stop_timer)
        .on(
          "dir:up plain:up dir:left plain:left dir:down plain:down dir:right plain:right",
          (evt, data) => {
            const dir = data.direction.angle;
            switch (dir) {
              case "up":
                this.buffer = MMC_Gimbal_L50.gimbal_pitch_ctrl(
                  1,
                  this.control_speed
                );
                break;
              // case "right":
              //   this.buffer = MMC_Gimbal_L50.gimbal_yaw_ctrl(
              //     1,
              //     this.control_speed
              //   );
              //   break;
              // case "left":
              //   this.buffer = MMC_Gimbal_L50.gimbal_yaw_ctrl(
              //     -1,
              //     this.control_speed
              //   );
              //   break;
              case "down":
                this.buffer = MMC_Gimbal_L50.gimbal_pitch_ctrl(
                  -1,
                  this.control_speed
                );
            }
          }
        );
    },
    start_timer() {
      this.buffer = null;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.buffer) {
            this.commit_directive(this.buffer);
          }
        }, 200);
      }
    },
    stop_timer() {
      this.buffer = MMC_Gimbal_L50.gimbal_pitch_ctrl(0, this.control_speed);
      this.commit_directive(this.buffer);

      // this.buffer = MMC_Gimbal_L50.gimbal_yaw_ctrl(0, this.control_speed);
      // this.commit_directive(this.buffer);

      if (this.buffer) {
        this.buffer = null;
      }
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  watch:{
    whachChange(){
      if(this.joystick){
        this.joystick.destroy()
      }
      this.init_rocker()
    }
  },
  mounted() {
    this.$emit("width", '416px')
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
  beforeDestroy() {
    this.stop_timer();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/index.scss";
</style>